import React, { Component } from 'react';
import '../styles/css/footer.css';
class Footer extends Component {
    render() {
        return (
        <div className="app-footer">
            <ul id="copyright">© Jerry Nguyen</ul>
        </div>
        );
    }
}
export default Footer;
